.auto-feedback-uploader-hint {
  color: #999;
  font-size: 12px;
  span {
    font-size: 16px;
    font-weight: 700;
    color: #ff4d4f;
  }
}

.asc-feedback-screenshot {
  display: flex;
  height: 100px;
  width: 100px;
  border: 1px #d9d9d9 dashed;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  background-color: rgba(46, 51, 70, 0.02);
  &:hover {
    border-color: #1a71ff;
  }
  .asc-feedback-screenshot-inner {
    text-align: center;
  }
}
.asc-feedback-screenshot-uploading {
  cursor: not-allowed;
  filter: opacity(0.6);
  &:hover {
    border-color: #d9d9d9;
  }
}

.merlion-dev-mode-dont-use-it-upload-picture-card-wrapper {
  flex: none;
}

.asc-feedback-screen-record-countdown {
  position: fixed;
  background-color: rgba(89, 95, 109, 0.5);
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10001;
  .title {
    margin-bottom: 12px;
    color: #fff;
    font-size: 20px;
  }
  .counter {
    color: #fff;
    font-size: 50px;
  }
}

.asc-feedback-screen-record-recording {
  position: fixed;
  top: 20px;
  left: 40%;
  padding: 4px 16px;
  line-height: 20px;
  background-color: rgba(89, 95, 109, 0.9);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  z-index: 9999;
  .icon {
    height: 8px;
    width: 8px;
    border: 1px #fff solid;
    border-radius: 4px;
    background-color: #FE4960;
  }
  .btn {
    cursor: pointer;
  }
}